<template>
  <span>
    <div v-for="(volume, i) in volumes" :key="i">
      <div class="mb-3 px-1 py-4" v-show="!volume.codigo">
        <b-card
          class="shadow"
          header="featured"
          header-tag="div"
          :header-class="
            'cor-background-azul ' +
            (btnFecharCaixaLoading ? ' caixa-carregando' : '')
          "
        >
          <template #header>
            <h4 class="mb-6 font-weight-bold" style="color: white">
              Caixa {{ i + 1 }} - {{ volume.codigo }}
            </h4>
          </template>
          <div
            v-for="(volumeInsumo, j) in volume.insumosNaoBipaveis"
            :key="'nb' + j"
            class="mb-0 px-1 py-1"
          >
            <b-card v-if="!volumeInsumo.insumo.descricao.match(descricaoMesa)">
              <b-row
                no-gutters
                style="justify-content: space-between !important"
              >
                <!--            <span v-if="!volumeInsumo.insumo.precisaSerBipado">-->
                <b-col cols="3" sm="8" md="8" lg="8" xl="8" align="start">
                  <label
                    style="
                      align-items: center;
                      font-family: 'Poppins';
                      font-size: 20px;
                    "
                    >{{ (volumeInsumo.insumo || {}).descricao || "" }}</label
                  >
                </b-col>
                <b-col cols="2" sm="1" md="1" lg="1" xl="1" align="center">
                  <b-button
                    class="number-modifier"
                    @click="volumeInsumo.quantidade--"
                    style="border-radius: 8px 0px 0px 8px !important"
                    pill
                    :disabled="btnFecharCaixaLoading"
                  >
                    -
                  </b-button>
                </b-col>
                <b-col cols="4" sm="2" md="2" lg="2" xl="2" align="center">
                  <input
                    class="py-0 px-0; my-0 mx-0 input-metragem text-center"
                    v-model="volumeInsumo.quantidade"
                    type="number"
                    :disabled="btnFecharCaixaLoading"
                  />
                </b-col>
                <b-col cols="2" sm="1" md="1" lg="1" xl="1" align="center">
                  <b-button
                    class="number-modifier"
                    @click="volumeInsumo.quantidade++"
                    style="border-radius: 0px 8px 8px 0px !important"
                    pill
                    :disabled="btnFecharCaixaLoading"
                  >
                    +
                  </b-button>
                </b-col>
                <!--            </span>-->
                <!---->
                <!--            <div v-else>-->
                <!--            </div>-->
              </b-row>
            </b-card>
          </div>

          <span
            v-for="(groupedItems, sku) in volume.insumosBipaveis"
            :key="'b' + sku"
          >
            <b-row>
              <b-col cols="4" align="start">
                <div>SKU:</div>
              </b-col>
            </b-row>
            <!-- <div class="input-group my-2"> -->
            <b-row style="align-items: center">
              <b-col cols="10">
                <input
                  type="text"
                  class="form-control"
                  v-model="groupedItems.dynamicInput"
                  @keyup.enter="handleDynamicInput($event, groupedItems)"
                  :placeholder="
                    'SKU: ' + (groupedItems.items[0]?.insumo?.descricao || '')
                  "
                  :disabled="btnFecharCaixaLoading"
                />
              </b-col>
              <b-col cols="2">
                <b-row>
                  <b-col v-if="!groupedItems.skuBipado">
                    <b-button
                      size="md"
                      variant="transparent"
                      class="rounded-circle mb-3"
                    >
                      <b-icon icon="check-circle" scale="2"></b-icon>
                    </b-button>
                  </b-col>
                  <b-col v-else>
                    <b-button
                      size="md"
                      variant="transparent"
                      class="rounded-circle mb-3"
                    >
                      <b-icon
                        scale="2"
                        icon="check-circle-fill"
                        variant="success"
                      ></b-icon>
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      v-if="groupedItems.skuBipado || groupedItems.loading"
                      aria-pressed="false"
                      size="md"
                      variant="transparent"
                      class="rounded-circle mb-3"
                      @click="limparValorDaBipagem($event, groupedItems)"
                    >
                      <b-icon
                        icon="x-circle"
                        scale="2"
                        variant="danger"
                      ></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- </div> -->

            <div
              v-for="volumeInsumo in groupedItems.items"
              :key="'b-item-' + volumeInsumo.insumo.insumoId"
            >
              <linha-producao-input-bipavel
                :insumo="volumeInsumo.insumo"
                :total-insumos="volume.insumos.length"
                @click="biparInsumo($event)"
                :bipagem-only="true"
                :sku-bipado="groupedItems.skuBipado"
                :ref="'insumo-' + volumeInsumo.insumo.insumoId"
              />
            </div>
          </span>
        </b-card>
      </div>
    </div>
  </span>
</template>

<script>
import Swal from "sweetalert2";
import LinhaProducaoInputBipavel from "../components/LinhaProducaoInputBipavelSku.vue";
export default {
  name: "ListagemCaixasLinhaInversores",
  components: { LinhaProducaoInputBipavel },
  data() {
    return {
      descricaoMesa: "MESA",
      groupedInsumos: [],
      currentInputIndex: 0,
      erroSku: null,
    };
  },
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    btnFecharCaixaLoading: {
      type: Boolean,
      required: true,
    },
  },
  // computed: {
  //   volumesMapped() {
  //     return this.volumes.map(v => ({
  //        ...v,
  //        insumosBipaveis: v.insumos.filter(i => i.insumo.precisaSerBipado),
  //        insumosNaoBipaveis: v.insumos.filter(i => !i.insumo.precisaSerBipado)
  //      }))
  //   }
  // },
  methods: {
    async biparInsumo({ event, insumo }) {
      this.$emit("bipar", { event, insumo });
    },

    handleDynamicInput(event, volumeInsumo) {
      if (this.validateSku(event, volumeInsumo)) return;

      console.log("volumeInsumo:", volumeInsumo);
      const { target } = event;
      const sku = target.value;

      const volume = this.volumes.find((volume) => volume.insumosBipaveis[sku]);
      const items = volume ? volume.insumosBipaveis[sku].items : [];

      if (!items || items.length === 0) {
        console.error("Nenhum item encontrado.");
        return;
      }

      const currentIndex = items.findIndex(
        (insumo) => insumo.insumoId === volumeInsumo.insumoId
      );
      const nextIndex =
        currentIndex === 0
          ? 0
          : currentIndex + 1 < items.length
          ? currentIndex + 1
          : 0;

      console.log("currentIndex:", currentIndex);
      console.log("nextIndex:", nextIndex);

      if (nextIndex >= 0) {
        const nextInsumoId = items[nextIndex].insumo.insumoId;
        const nextInput = document.querySelector(`.insumo-${nextInsumoId}`);

        console.log("nextInput:", nextInput);
        console.log("nextInsumoId:", nextInsumoId);
        if (nextInput) {
          this.$nextTick(() => {
            const nextInput = document.querySelector(`.insumo-${nextInsumoId}`);
            if (nextInput && !nextInput.disabled) {
              nextInput.focus();
            }
          });
        }
      }
    },

    validateSku(event, volumeInsumo) {
      const { target } = event;
      const sku = target.value;
      console.log({ sku, volumeInsumo });
      const skuExists = volumeInsumo.items.some(
        (item) => item.insumo.winthorCodigoProduto == sku
      );

      if (!skuExists) {
        Swal.fire({
          title: "Erro de Validação",
          text: "SKU não encontrado! Verifique o código e tente novamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
        target.value = "";
        target.focus();
        return true;
      }

      this.volumes.forEach((volume) => {
        if (volume.insumosBipaveis[sku]) {
          volume.insumosBipaveis[sku].skuBipado = true;
        }
      });

      return false;
    },
    limparValorDaBipagem(event, insumo) {
      insumo.dynamicInput = "";
      insumo.skuBipado = false;
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.number-modifier {
  border-style: none;
  background: #ff7d27;
}

.input-metragem {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 80%;
  border-radius: 0px 0px 0px 0px;
}

.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
