<template>
  <!--  <div>-->
  <!--    <table>-->
  <!--      <tbody v-for="(insumo, i) in [insumo]" :key="i">-->
  <b-card
    :class="
      (conferido ? 'insumo-conferido' : '') +
      (erroMotivo && !erroCorrigido ? ' insumo-com-erro' : '')
    "
  >
    <b-row class="">
      <b-col
        :cols="responsivityInput.cols"
        :sm="responsivityInput.sm"
        :md="responsivityInput.md"
        :lg="responsivityInput.lg"
        :xl="responsivityInput.xl"
        align="start"
      >
        <div
          class="mt-2"
          style="align-items: center; font-family: 'Poppins'; font-size: 20px"
        >
          {{ insumo.descricao }} - {{ insumo.erroMotivo }}
          {{ `${insumo.index}/${totalInsumos}` }}
        </div>
        <div class="mt-2">
          <input
            type="text"
            :class="
              'prod-cod item-bipagem insumo-' +
              insumo.insumoId +
              (insumo.bipado && !erroMotivo ? ' insumoBipado' : '') +
              (insumo.loading ? ' input-loading' : '') +
              (erroMotivo && !erroCorrigido ? ' insumo-com-erro' : '')
            "
            @keyup.enter="validarBipagem($event, insumo)"
            :value="insumo.serial"
            :disabled="
              insumo.bipado || insumo.loading || insumo.loading || !skuBipado
            "
          />
          <label
            v-if="erroMotivo"
            style="align-items: center; font-size: 20px; color: red"
          >
            {{ erroMotivo.toUpperCase() }}
          </label>
        </div>
      </b-col>

      <b-col
        cols="2"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        align="end"
        v-if="!conferido && !erroMotivo && !bipagemOnly"
        class="mt-5"
      >
        <default-button
          v-if="!erroMotivo"
          :name="'Reportar problema'"
          class="text-center"
          color="red-color"
          @click="reportarProblema"
        ></default-button>
      </b-col>

      <b-col
        cols="2"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        align="end"
        v-if="erroMotivo && !erroCorrigido && !bipagemOnly"
        class="mt-5"
      >
        <default-button
          :name="'Informar problema corrigido'"
          class="text-center mb-4"
          color="yellow-color"
          @click="reportarCorrecaoErro"
        ></default-button>
      </b-col>

      <!--        <b-col>-->
      <!--          <div v-if="!insumo.bipado">-->
      <!--            <b-button-->
      <!--                size="lg"-->
      <!--                variant="transparent"-->
      <!--                class="mt-4 rounded-circle p-2"-->
      <!--            >-->
      <!--              <b-icon icon="check-circle" scale="2"></b-icon>-->
      <!--            </b-button>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            <b-button-->
      <!--                size="lg"-->
      <!--                variant="transparent"-->
      <!--                class="mt-4 rounded-circle p-2"-->
      <!--            >-->
      <!--              <b-icon-->
      <!--                  scale="2"-->
      <!--                  icon="check-circle-fill"-->
      <!--                  variant="success"-->
      <!--              ></b-icon>-->
      <!--            </b-button>-->
      <!--          </div>-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--          <b-button :disabled="insumo.bipado || insumo.loading"-->
      <!--                    aria-pressed="false"-->
      <!--                    size="lg"-->
      <!--                    variant="transparent"-->
      <!--                    class="mt-4 rounded-circle p-2"-->
      <!--                    @click="limparValorDaBipagem($event, insumo)"-->
      <!--          >-->
      <!--            <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>-->
      <!--          </b-button>-->
      <!--        </b-col>-->
    </b-row>
  </b-card>
  <!--      </tbody>-->
  <!--    </table>-->
  <!--  </div>-->
</template>
<script>
// import { BipagemService } from "../../../../services/pedido_web_producao/linha_paineis/BipagemService";
// import {UsuarioLogado} from "../../../../services/login/UsuarioLogado";
import DefaultButton from "../../../../../../../components/DefaultButton";

export default {
  name: "LinhaProducaoInputBipavel",
  components: { DefaultButton },
  props: {
    insumo: {
      type: Object,
      required: true,
      validator: (insumo) => {
        return !!insumo;
        // const {index, insumoId, descricao, bipado, serial, loading, volumeId} = insumo
        // return [
        //   index, insumoId, descricao, bipado, serial, loading, volumeId
        // ].every(attr => attr !== undefined);
      },
    },
    conferido: { type: Boolean, default: false },
    // loading: {type: Boolean, required: true },
    totalInsumos: { type: Number, required: true },
    erroMotivo: { type: String, default: "" },
    erroCorrigido: { type: Boolean, default: false },
    bipagemOnly: { type: Boolean, default: false },
    skuBipado: { type: Boolean, default: false },
  },
  computed: {
    responsivityInput() {
      return {
        cols: this.bipagemOnly ? 1 : 2,
        sm: this.bipagemOnly ? 12 : 9,
        md: this.bipagemOnly ? 12 : 9,
        lg: this.bipagemOnly ? 12 : 9,
        xl: this.bipagemOnly ? 12 : 9,
      };
    },
  },
  methods: {
    limparValorDaBipagem(event, insumo) {
      console.log({ event, insumo });
    },
    async validarBipagem(event, insumo) {
      await this.$emit("click", { event, insumo });
    },
    async reportarProblema() {
      this.$emit("reportar-problema");
    },
    async reportarCorrecaoErro() {
      this.$emit("reportar-correcao-erro");
    },
  },
};
</script>
<style scoped>
.insumoBipado {
  border: 2px solid green;
  border-radius: 10px;
}

.input-loading {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
}

.insumo-conferido {
  border-color: green;
  border-style: solid;
}

.insumo-com-erro {
  border-color: red;
  border-style: solid;
}
</style>
