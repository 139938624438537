import { render, staticRenderFns } from "./LinhaProducaoInputBipavelSku.vue?vue&type=template&id=6cc63a33&scoped=true&"
import script from "./LinhaProducaoInputBipavelSku.vue?vue&type=script&lang=js&"
export * from "./LinhaProducaoInputBipavelSku.vue?vue&type=script&lang=js&"
import style0 from "./LinhaProducaoInputBipavelSku.vue?vue&type=style&index=0&id=6cc63a33&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cc63a33",
  null
  
)

export default component.exports